
.hero {
    background-image: url("/src/Img/MacBook Pro 16_ - 23.svg");
    width: 100%;

    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    background-position: center;
    background-size: cover;
}

.hero_cont {
    padding: 100px 0 0 0;
}

.test8 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container3 {
    max-width: 1728px;
    width: 100%;
    padding: 0 0 0 10%;
    margin: 0 auto;
    overflow-x: hidden;
}

/*.hero_text {*/
/*    text-align: center;*/
/*    max-width: 800px;*/
/*}*/

.hero-content span {
    color: #D48C27;
    font-size: 64px;
    font-weight: 500;
}

.hero-content p {
    color: #D48C27;
    font-size: 48px;
    font-weight: 400;
}

.hero-content_title_1 p {
    margin-top: 20px;
    line-height: 2.0;
    color: #FFFFFF;
    font-size: 24px;
}

.hero-content {
    width: 70%;
    padding-left: 10%;

}

.hero_img {
    width: 500px;
}

/* Responsive styles for smaller screens */
@media (max-width: 1000px) {
    .hero {
        background-size: cover;
        padding-top: 100px;

    }

    .hero_img {
        width: 30%;
    }

    .hero_cont {
        padding-top: 0;
    }

    .hero_text {
        text-align: left;
        max-width: 90%; /* Adjust max-width for smaller screens */
        margin: 0 auto; /* Center horizontally */
    }

    .hero_text h1 {
        font-size: 48px; /* Adjust font size for smaller screens */
    }

    .hero_text p {
        max-width: 100%; /* Adjust max-width for smaller screens */
    }

    .hero-content span {
        color: #D48C27;
        font-size: 25px;
        font-weight: 800;
    }

    .hero-content p {
        line-height: 2.0;
        color: #FFFFFF;
        font-size: 10px;

    }
}

@media screen and (max-width: 480px) {
    .hero {
        background-size: cover;
        padding-top: 80px;
    }

    .hero_text {
        text-align: left;
        max-width: 90%; /* Adjust max-width for smaller screens */
        margin: 0 auto; /* Center horizontally */
    }

    .hero_text h1 {
        font-size: 48px; /* Adjust font size for smaller screens */
    }

    .hero_text p {
        max-width: 100%; /* Adjust max-width for smaller screens */
    }

    .hero-content span {
        color: #D48C27;
        font-size: 15px;
        font-weight: 800;
    }

    .hero-content p {
        line-height: 2.0;
        color: #FFFFFF;
        font-size: 6px;

    }

    .hero_img {
        width: 30%;
    }

    .hero_img img {
        width: 100%;
    }

    .container3 {
        max-width: 1728px;
        width: 100%;
        /* padding: 0 0 0 10%; */
        margin: 0 auto;
        overflow-x: hidden;
        padding: 0;
    }

    .hero_cont {
        padding: 0;
    }
}