*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Helvetica', 'Arial', sans-serif;
    overflow-x: hidden;

}
body, html {
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    color: inherit;
    line-height: 1;
    cursor: pointer;
}

.btn{
    background:#D48C27 ;
    color: #FFFFFF;
    padding: 14px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;

}
.container2{
    max-width: 1728px;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}
.section{
    padding: 5% 10% 5% 10%;
}