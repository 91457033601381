
.let_use_contaniner {
    padding: 100px 0 100px 0;
}

.title_let_us {
    font-size: 24px;

}

.let_use_states_text {
    display: flex;
    justify-content: space-between;
}

.letuse_cont_2 {
    display: flex;
    flex-direction: column;
}

.letuse_cont_2 p {
    font-size: 64px;
    padding-top: 50px;
}

.letuse_cont_2 span {
    font-size: 16px;
    padding-top: 5px;
}

.letuse_cont_3 {
    font-size: 24px;
    padding-top: 5px;
}

/* Responsive styles for smaller screens */
@media (max-width: 1000px) {
    .letuse_cont_2 p {
        font-size: 36px;
    }

    .letuse_cont_2 span {
        font-size: 16px;
        margin-top: 5px;
    }

    .letuse_cont_3 {
        font-size: 24px;
        padding-top: 5px;
    }
}

@media (max-width: 480px) {
    .letuse_cont_2 p {
        font-size: 36px;
    }

    .letuse_cont_2 span {
        font-size: 10px;
        margin-top: 5px;
    }

    .letuse_cont_3 {
        font-size: 20px;
        padding-top: 20px;
    }

    .title_let_us h3 {
        padding-top: 10px;
    }
}