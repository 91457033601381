.container_whey {
    background-image: url("/src/Img/MacBook Pro 16_ - 25.png");
    width: 100%;
    background-size: cover;
    background-position: center;
}

.why_container {
    min-height: 40vh;
    padding: 100px 0;
}

.why_title {
    text-align: left;
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 600;
    text-transform: uppercase;
}

.why_title_container {
    padding-bottom: 50px;
}

.whey_display_grid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.whey_item {
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px #00000015;
    animation: fadeIn 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.itb_text_s span {
    color: #d48c27;
    font-size: 45px;
}

.whey_itm_imag {
    height: 256px;
    border-radius: 15px 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #444444;
}

.whey_info {
    padding: 20px;
    color: #FFFFFF;
}

.whey_info span {
    font-size: 24px;
    font-weight: 500;
}

.whey_info p {
    font-size: 16px;
    padding-top: 20px;
    white-space: pre-line; /* To handle line breaks in text2 */
}

.whey_item:hover {
    background-color: #d48c27;
    transition: background-color 0.3s ease;
}

.whey_itm_imag:hover {
    transform: translateY(20px);
    transition: transform 0.5s ease;
}

@media screen and (max-width: 1000px) {
    .whey_display_grid {
        gap: 16px;
    }
}

@media screen and (max-width: 480px) {
    .whey_display_grid {
        grid-template-columns: 1fr;
    }

    .container_whey {
        min-height: 80vh;
    }

    .why_title {
        font-size: 24px;
    }

    .itb_text_s span {
        font-size: 2em;
    }
}
