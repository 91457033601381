.our_services {
    width: 100%;
    background-image: url("/src/Img/022.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 0; /* Add some padding to top and bottom */
}

.our_s_title {
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.cont_ser {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Grid with flexible columns */
    gap: 24px;
    width: 100%;
    min-height: 280px;
    padding-top: 8px;
}

.ser_cont {
    height: 256px;
    background-color: #444444;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 0.2px solid rgba(114, 226, 174, 0.2);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s, background-color 0.3s, border-color 0.3s;
}

.ser_cont_img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Add margin for spacing between image and text */
}


.ser_cont_text {
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
    line-height: 1.4;
}

.ser_cont:after {
    content: "";
    position: absolute;
    top: 150%;
    left: -200px;
    width: 120%;
    transform: rotate(50deg);
    background-color: #FFFFFF;
    height: 18px;
    filter: blur(30px);
    opacity: 0.5;
    transition: 1s;
}

.ser_cont:hover:after {
    width: 225%;
    top: -100%;
}

.ser_cont:hover {
    background-color: transparent;
    transform: translateY(-8px);
    border-color: #D48C27;
}

/* Media query for smaller screens */
@media screen and (max-width: 1000px) {
    .our_s_title {
        font-size: 36px;
    }

    .cont_ser {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Adjust for smaller screens */
        gap: 16px;
    }

    .ser_cont {
        height: auto; /* Allow height to adjust naturally */
    }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
    .our_s_title {
        font-size: 24px;
    }

    .cont_ser {
        grid-template-columns: 1fr; /* Single column for very small screens */
    }

    .ser_cont {
        width: 100%;
        height: 256px;
    }
}
