.footer_container {
    background-color: #1e1e1e;
    padding: 40px;
}

.footer_grid {
    display: grid;
    grid-template-columns: 20% 50% 20% ;
    /*grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));*/
    gap: 40px;
    align-items: start;
}


.logo1{
    grid-column: 1 / span 1;
    grid-row: 1;
}
.text_logo{
    grid-column: 1 / span 1;
    grid-row: 2;
}
.email_text{
    grid-column: 2 / span 1;
    grid-row: 2;
}
.social{
    grid-column: 3 / span 1;
    grid-row: 2;
}

.footer_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer_logo img {
    width: 150px;
}

.footer_text  p {
    font-size: 16px;
    color: #f5f5f5;
}

.footer_subscription {
    text-align: center;
    color: #f5f5f5;
    font-size: 16px;
}

.footer_input {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.footer_input input {
    width: 250px;
    padding: 10px;
    border: 1px solid #D48C27;
    border-radius: 5px;
    margin-right: 10px;
}

.footer_input button {
    background-color: #D48C27;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.footer_social {
    text-align: center;
    color: #f5f5f5;
}

.footer_social p {
    margin-bottom: 10px;
}

.social_icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social_icons i {
    font-size: 24px;
    color: #f5f5f5;
    cursor: pointer;
}

.social_icons i:hover {
    color: #D48C27;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .footer_input {
        flex-direction: column;
    }

    .footer_input input, .footer_input button {
        width: 100%;
        margin-top: 10px;
    }

    .footer_grid {
        display: grid;
        grid-template-columns: 75% ;
        /*grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));*/
        gap: 40px;
        align-items: start;
        text-align: center;
        justify-content: center;
    }


    .logo1{
        grid-column: 1 / span 1;
        grid-row: 1;
    }
    .text_logo{
        grid-column: 1 / span 1;
        grid-row: 2;
    }
    .email_text{
        grid-column: 1 / span 1;
        grid-row: 2;
    }
    .social{
        grid-column: 1 / span 1;
        grid-row: 3;
    }
}

@media (max-width: 480px) {
    .footer_input {
        flex-direction: column;
    }

    .footer_input input, .footer_input button {
        width: 100%;
        margin-top: 10px;
    }

    .footer_grid {
        display: grid;
        grid-template-columns: 100% ;
        /*grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));*/
        gap: 40px;
        align-items: start;
        text-align: center;
    }


    .logo1{
        grid-column: 1 / span 1;
        grid-row: 1;
    }
    .text_logo{
        grid-column: 1 / span 1;
        grid-row: 2;
    }
    .email_text{
        grid-column: 1 / span 1;
        grid-row: 3;
    }
    .social{
        grid-column: 1 / span 1;
        grid-row: 4;
    }
}
