.about-section {
    background-image: url("/src/Img/MacBook Pro 16_ - 25.png");
    background-size: cover;

}

.about_use_container {
    padding: 100px 0 100px 0;
}

.about_use_section {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 10%;
}

.title span {
    color: #FFFFFF;
    font-size: 48px;
    margin-bottom: 50px;
}

.title p {
    color: #FFFFFF;
    font-size: 24px;
}

.title_p {
    display: flex;
    text-align: left;

}

.section2 {
    padding-top: 5%;
    padding-bottom: 5%;
}

@media screen and (max-width: 1000px) {
    .title span {
        font-size: 20px;
        margin-bottom: 10px;

    }

    .about_use_section {
        column-gap: 40px;
    }

    .title p {
        font-size: 15px;
    }

    .about_use_section img {
        width: 100%;
    }
}


@media screen and (max-width: 480px) {
    .title span {
        font-size: 20px;
        margin-bottom: 10px;

    }

    .about_use_section {
        column-gap: 40px;
    }

    .title p {

        font-size: 10px;
    }
}