.slider_contanier {
    width: 100%;
    background-image: url("/src/Img/033.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.title-slider {
    color: #D48C27;
    font-size: 24px;
    margin-bottom: 50px;


}


.image_wrapper {
    margin: 0px 30px;

}

.container5 {
    max-width: 1728px;
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}


.slider_block {
    margin: 100px 0 100px 0;
}

.image_wrapper2 img {
    width: 200px;
    height: 50px;
}

@media screen and (max-width: 480px) {
    .title-slider h1 {
        font-size: 24px;
    }


}