/* Navigation */
nav {
    width: 100%;
    background: #1E1E1E;
    color: #FFFFFF;
    padding: 4px 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}

.logo {
    width: 100px;
    padding: 6px 0;
    height: 60px;
}

.container{
    padding: 0 5%;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav ul li {
    display: inline-block;
    margin: 5px 20px;
    font-size: 16px;
}

nav ul li img {
    height: 20px;
}

.menu_button {
    display: none;
}

/* Sidebar */
.sidebar {
    color: #1E1E1E;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}


ul li span:hover {
    color: #D48C27;

}
.navbar_active{
    background: none;
    transition: .10s;
}
.navnar{
    background:none;
    transition: .10s;
}

.sidebar ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar li {
    width: 100%;
}

/* Responsive styles */
@media (max-width: 1000px) {


    nav ul li {
        margin: 20px 10px;
    }

    .menu_button {
        display: block;
    }

    .hideOnMobile {
        display: none;
    }

    .sidebar {
        width: 100%;
    }
}

@media (max-width: 480px) {
    nav ul li {
        margin: 20px 5px;
    }
}
