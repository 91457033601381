.register_container2 {
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
}

.registr_container {
    padding: 100px 0 100px 0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-size: cover;
    background-position: center;
}

.modal-content h2 {
    margin-bottom: 25px;
    margin-top: 25px;
    text-align: center;
    padding: 15px;
}


.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.9);
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.modal-content button {
    padding: 14px 45px;
    margin-top: 10px;
    background: #D48C27;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modal-content button:hover {
    background-color: #45a049;
}

.modal-content button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.success-text {
    color: white;
}

@media (max-width: 600px) {
    .modal-content {
        width: 90%;
    }
}

.register_back {
    background-image: url("/src/Img/Registr_Page.png");
    width: 100%;
    background-size: cover;
    background-position: center;

}

.register_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: end;
}

.register_container_line {
    display: flex;
    gap: 50px;
}

.container_register2 {
    display: flex;
    padding-bottom: 50px;
}

.input-group {
    position: relative;
    margin-bottom: 60px;
}

.input-group label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
    padding: 0 5px;
    pointer-events: none;
    transition: .5s;
}

.input-group input {
    width: 320px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    padding: 0 10px;
    background: transparent;
    border: 1.2px solid #fff;
    outline: none;
    border-radius: 5px;
}

.input-group label {
    border-top-style: solid;
    border-top-width: 11px;
    border-top-color: black;
}


.input-group input:focus ~ label,
.input-group input:valid ~ label {
    top: 0;
    font-size: 12px;
    background: #D48C27;
    border-radius: 5px;
}

.button_reg2 {
    width: 141px;
    height: 51px;
    color: #fff;
    background-color: #D48C27;
    border-radius: 10px;
    transition: 1s ease-in-out;
    font-size: 16px;
    cursor: pointer;
}


.text_register {
    margin-bottom: 45px;
    color: #D48C27;
    font-size: 48px;
}

.text_register_info {
    color: aliceblue;
    margin-bottom: 50px;

}


@media (max-width: 1000px) {
    .register_container_line {
        display: flex;
        flex-wrap: wrap;
    }

    .register_container {
        display: block;
    }

    .reg_title {

        padding-top: -50px;
    }

    .register_back {
        background-size: 100% 915px;;
    }
}


@media (max-width: 480px) {
    .register_container2 {
        max-width: 380px;
        overflow: auto;
        display: contents;
    }

    .input-group input {
        width: 100%;
    }

    .text_register p {
        font-size: 24px;
    }

    .text_register_info {
        font-size: 12px;
    }
}
